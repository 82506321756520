<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline">
      <b-card-title class="text-primary">
        {{ title }}
      </b-card-title>
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="grid-select mb-3">
        <b-form-group
          label="Chọn năm"
        >
          <v-select
            v-model="paramsChartSales.yearSelected"
            :clearable="false"
            :options="yearOptions"
            :reduce="x => x"
            placeholder="Chọn năm"
          />
        </b-form-group>

        <b-form-group
          label="Chọn tháng"
        >
          <v-select
            v-model="paramsChartSales.monthSelected"
            :clearable="false"
            :options="monthOptions"
            :reduce="x => x.value"
            label="label"
            placeholder="Chọn tháng"
          />
        </b-form-group>
      </div>

      <!-- apex chart -->
      <vue-apex-charts
        v-if="chartData[0].isShow"
        type="line"
        height="300"
        :options="salesLine.chartOptions"
        :series="chartData"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormGroup,
    vSelect,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    chartData: {
      type: Array,
      default() { return [] },
    },
    paramsChartSales: {
      type: Object,
      default() { return {} },
    },
  },
  data() {
    return {
      salesLine: {
        series: [
          {
            name: 'Sales',
            data: [140, 180, 150, 205, 160, 295, 125, 255, 205, 305, 240, 295],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: 'line',
            dropShadow: {
              enabled: true,
              top: 18,
              left: 2,
              blur: 5,
              opacity: 0.2,
            },
            offsetX: -10,
          },
          stroke: {
            curve: 'smooth',
            width: 4,
          },
          grid: {
            borderColor: '#ebe9f1',
            padding: {
              top: -20,
              bottom: 5,
              left: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: ['#df87f2'],
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              inverseColors: false,
              gradientToColors: [$themeColors.primary],
              shadeIntensity: 1,
              type: 'horizontal',
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
            },
            axisTicks: {
              show: false,
            },
            categories: this.chartData.date,
            axisBorder: {
              show: false,
            },
            tickPlacement: 'on',
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: '#b9b9c3',
                fontSize: '0.857rem',
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(1)}k` : val
              },
            },
          },
          tooltip: {
            x: { show: false },
          },
        },
      },
      yearSelected: null,
      yearOptions: this.generalListYears(),
      monthSelected: 1,
      monthOptions: [
        {
          value: 1,
          label: 'Tháng 01',
        },
        {
          value: 2,
          label: 'Tháng 02',
        },
        {
          value: 3,
          label: 'Tháng 03',
        },
        {
          value: 4,
          label: 'Tháng 04',
        },
        {
          value: 5,
          label: 'Tháng 05',
        },
        {
          value: 6,
          label: 'Tháng 06',
        },
        {
          value: 7,
          label: 'Tháng 07',
        },
        {
          value: 8,
          label: 'Tháng 08',
        },
        {
          value: 9,
          label: 'Tháng 09',
        },
        {
          value: 10,
          label: 'Tháng 10',
        },
        {
          value: 11,
          label: 'Tháng 11',
        },
        {
          value: 12,
          label: 'Tháng 12',
        },
      ],
    }
  },
  methods: {
    generalListYears() {
      const date = new Date()
      return Array.from(new Array(10), (v, idx) => date.getFullYear() - idx)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.grid-select
  max-width: 360px
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr))
  gap: 16px
</style>
