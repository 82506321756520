<template>
  <div>
    <h4 class="text-primary">
      {{ title }}
    </h4>
    <line-chartjs
      :chart-data="chartData"
    />
  </div>
</template>

<script>
import LineChartjs from './LineChartjs.vue'

export default {
  name: 'LineChartSection',
  components: {
    LineChartjs,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    chartData: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {}
  },

  methods: {},
}
</script>

<style lang="sass">

</style>
